import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import SimpleTrendChart from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/SimpleTrendChart';
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import { useGetPrice } from '~/modules/screener/components/invesement-consultant/SymbolChangeRanking/useGetPrice';
import { toPercentage } from '~/utils/toPercentage';
import { first, last } from 'lodash';
import { useThemeStore } from '~/components/theme/useThemeStore';
const StockFuturesSymbol = memo(function StockFuturesSymbol(props) {
    //const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper)
    const theme = useThemeStore(s => s.theme);
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const charting = useSnapshot(store.charting);
    const selected = charting.symbol === props.symbol;
    const date = useSnapshot(staticStore).tradedDate.day0;
    const data = useGetPrice(date, 365, 5, props.symbol);
    const change = toPercentage(last(data)?.close ?? 0, first(data)?.close ?? 0, true);
    const changeSymbol = change > 0 ? '+' : '';
    const strategyConfig = props.strategyConfig ?? undefined;
    return (<classes.container className={theme} select={selected} onClick={() => {
            strategyConfig !== undefined && store.charting.setStrategy(props.strategyConfig);
            store.charting.changeSymbol(props.symbol);
            strategyConfig !== undefined && store.charting.updateFromStrategyState();
        }}>
      <classes.nameItem>
        <QuoteValueItem.arrow.Display quote={quote} changeType='closeChange'/>
        <SymbolName symbol={props.symbol}/> {props.symbol}
      </classes.nameItem>
      <classes.changeItem>
        近5日:
        <classes.changeValue fill={change} className={theme}>
          {changeSymbol}
          {change}%
        </classes.changeValue>
      </classes.changeItem>
      <classes.closeItem className={theme}>
        <QuoteValueItem.ohlc.Display quote={quote} ohlcType='close'/>
      </classes.closeItem>
      <classes.chartItem>
        <SimpleTrendChart symbol={props.symbol} interval={'hour'} timeCount={120} dataInterval={'1H'}/>
      </classes.chartItem>
    </classes.container>);
});
const StockFuturesHeader = memo(function StockFuturesHeader() {
    const theme = useThemeStore(s => s.theme);
    return (<classes.titleContainer className={theme}>
      <div>商品</div>
      <div>收盤</div>
      <div>近5日走勢</div>
    </classes.titleContainer>);
});
export default {
    body: StockFuturesSymbol,
    header: StockFuturesHeader,
};
const classes = {
    container: styled.div `
    display: flex;
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 32% 16% 52%;
    grid-template-rows: 50% 50%;
    font-size: 13px;
    border-radius: 4px;
    padding: 4px;
    flex-shrink: 0;
    cursor: pointer;

    &.dark {
      border: 1px solid ${props => (props.select === true ? '#fafafa' : '#565656')};
      &:hover {
        background-color: #252525;
        transition: 0.3s;
      }
    }

    &.light {
      border: 1px solid ${props => (props.select === true ? '#565656' : '#dedede')};
      &:hover {
        background-color: #fafafa;
        transition: 0.3s;
      }
    }
  `,
    titleContainer: styled.div `
    display: grid;
    grid-template-columns: 32% 16% 52%;
    height: 32px;
    width: 100%;
    border-radius: 2px;
    font-size: 14.8px;
    &.dark {
      background-color: #454545;
      border: 1px solid #454545;
    }
    &.light {
      background-color: #f0f0f0;
      border: 1px solid #eaeaea;
    }
    & > * {
      ${fill_horizontal_all_center};
    }
  `,
    nameItem: styled.div `
    ${fill_horizontal_cross_center};
    grid-column: 1;
    grid-row: 1;
    font-weight: 500;
    white-space: nowrap;
  `,
    closeItem: styled.div `
    ${fill_horizontal_all_center};
    grid-column: 2;
    grid-row: 1/3;
    font-size: 14.8px;
    font-weight: 600;

    & > * {
      &.dark {
        color: #fafa33 !important;
      }
      &.light: {
        color: #454545 !important;
      }
    }
  `,
    changeItem: styled.div `
    ${fill_horizontal_all_center};
    grid-column: 1;
    grid-row: 2;
    gap: 4px;
  `,
    changeValue: styled.div `
    &.dark {
      color: ${props => (props.fill > 0 ? '#fb0000' : props.fill < 0 ? '#00ff00' : '#fafafa')};
    }
    &.light: {
      color: ${props => (props.fill > 0 ? '#fb0000' : props.fill < 0 ? '#00aa00' : '#252525')};
    }
  `,
    chartItem: styled.div `
    grid-column: 3;
    grid-row: 1/3;
    padding: 4px;
  `,
};
