import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useStkfutDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { stocknerve_1222 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_1222';
import { stocknerve_2330 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_2330';
import { stocknerve_as1 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_as1';
import { StockRatioGraph } from '../daddy960_goodway/component/StockRatioGraph';
import { StockScoreRankSlider } from '../daddy960_goodway/component/StockScoreRankSlider';
import { StockScoreSort } from '../daddy960_goodway/component/StockScoreSort';
import { StockScoreTableHeader } from '../daddy960_goodway/component/StockScoreTable';
import { StockVolumeListTable } from '../daddy960_goodway/component/StockVolumeListTable';
import { staticStore } from '../heineken_template/_private/staticStore';
import { store } from '../heineken_template/_private/store';
import { groupStore, watchListConfig } from './daddy960_stocknerve_SidePane2';
export const dayStore = proxy({
    load: false,
});
export const stocknerve_store = proxy({
    pageState: 'index',
});
export const Daddy960_stocknerve_SidePane1 = memo(function Daddy960_stocknerve_SidePane1() {
    const loadState = useSnapshot(dayStore).load;
    const tradedDate = useSnapshot(staticStore).tradedDate.days[2];
    const charting = useSnapshot(store.charting);
    const symbol = charting.symbol;
    const prefix = symbol.substr(0, 2);
    const suffix = symbol.substr(2);
    const isStockFutures = /^[A-Z]{2}$/?.test(prefix) || suffix === 'F-1';
    useEffect(() => {
        if (loadState === true) {
            store.charting.setVisibleRange(tradedDate);
        }
    }, [tradedDate, loadState]);
    const [resetIndicators, setResetIndicators] = useState(false);
    useEffect(() => {
        //不是1k 或 不是股期 移除指定指標
        if (charting.interval !== '1' || !isStockFutures) {
            store.charting.removeIndicators([stocknerve_as1, stocknerve_2330, stocknerve_1222]);
            //標記已經切換到1k以外週期
            setResetIndicators(true);
        }
        //是1k 或 股期 加入指定指標
        if (charting.interval === '1' && resetIndicators === true && isStockFutures) {
            store.charting.addIndicators([stocknerve_as1, stocknerve_2330, stocknerve_1222]);
            //store.charting.panesRatio = 66
            setResetIndicators(false);
        }
    }, [charting.interval, resetIndicators, isStockFutures]);
    return (<styleds.container>
        <styleds.dashboardHeader>
          <div>氣氛靈敏度</div>
          <div>分數排序</div>
        </styleds.dashboardHeader>
        <styleds.dashboard>
          <StockScoreRankSlider />
          <StockScoreSort />
        </styleds.dashboard>
        <styleds.content height={'32px'}>
          <StockRatioGraph />
        </styleds.content>
        <WatchListGroup />
        <styleds.content height={'32px'}>
          <StockScoreTableHeader />
        </styleds.content>
        <styleds.content height={'calc(100% - 188px)'}>
          <StockVolumeList />
        </styleds.content>
      </styleds.container>);
});
/** 觀察清單上的群組選擇器 (預設)(群組1)... */
export const WatchListGroup = memo(function WatchListGroup() {
    const groupState = useSnapshot(groupStore);
    const isDefaultSelect = groupState.defaultData === true;
    return (<styleds.watchListGroupContainer>
      <styleds.watchListGroupButton selected={isDefaultSelect} onClick={() => (groupStore.defaultData = true)}>
        全部
      </styleds.watchListGroupButton>
      {watchListConfig.map((item, index) => {
            const isSelect = item.groupName === groupState.watchListData && !isDefaultSelect;
            return (<styleds.watchListGroupButton key={index} selected={isSelect} onClick={() => {
                    groupStore.watchListData = item.groupName;
                    groupStore.defaultData = false;
                }}>
            {item.displayText}
          </styleds.watchListGroupButton>);
        })}
    </styleds.watchListGroupContainer>);
});
/** 顯示預設股期商品 或是自選商品(過濾非股期商品) */
export const StockVolumeList = memo(function StockVolumeList() {
    const groupState = useSnapshot(groupStore).watchListData;
    const { state } = useSymbolWatchList(groupState);
    const symbolListArray = state.symbolListArray;
    /** 自選股的股期商品 */
    const filteredData = symbolListArray?.filter((item) => {
        if (item.length !== 5) {
            // 長度不符合條件
            return false;
        }
        const prefix = item.substr(0, 2);
        const suffix = item.substr(2);
        if (!/^[A-Z]{2}$/.test(prefix) || suffix !== 'F-1') {
            // 前面兩子字串必須為A-Z且後面字串需要為F-1
            return false;
        }
        return true;
    });
    /** 股期成交量前30名 */
    const stkRankData = useStkfutDailyRankResource({
        sort_by: 'volume',
        order_by: 'desc',
        volume_gte: 1,
        volume_lte: 10000000,
        amount_gte: 0,
        amount_lte: 0,
        limit: 50,
    }, 1).data?.map(s => s.symbol) ?? [];
    const resultData = useSnapshot(groupStore).defaultData === true ? stkRankData : filteredData;
    return (<StockVolumeListTable changeSymbol={true} data={resultData} onClick={() => (dayStore.load = false)}/>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    font-size: 14px;
    user-select: none;
  `,
    content: styled.div `
    width: 100%;
    height: ${props => props.height};
  `,
    dashboardHeader: styled.div `
    ${fill_horizontal_all_center};
    grid-template-columns: 70% 30%;
    height: 32px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    & > div {
      ${fill_horizontal_all_center};
      background-color: #252525;
      border-radius: 5px;
    }
  `,
    dashboard: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    & > div {
      background-color: #252525;
      border-radius: 5px;
    }
  `,
    watchListGroupContainer: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    gap: 4px;
  `,
    watchListGroupButton: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: ${props => (props.selected ? '#4759cc' : '#252525')};
    &:hover {
      background-color: ${props => (props.selected ? '#4759cccc' : '#353535')};
    }
  `,
};
