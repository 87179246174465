import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const stocknerve_2330 = createIndicator({
    id: 'stocknerve-2330',
    displayName: 'stocknerve_2330',
    constructorScope: {
        init(context, inputCallback) {
            const interval = context.symbol.period;
            const symbol_ = context.symbol.info?.name ?? '';
            const prefix = symbol_.substr(0, 2);
            const suffix = symbol_.substr(2);
            const isStockFutures = /^[A-Z]{2}$/?.test(prefix) || suffix === 'F-1';
            if (interval === '1' && isStockFutures) {
                const ticker = this.PineJS.Std.ticker(context)
                    .toString()
                    .replace(/[^\w-]/g, '');
                // Register bvav tbta (1)
                context.new_sym(ticker + '#goodway_sfChip960', this.PineJS.Std.period(this._context));
                // Register uvdv1 (2)
                context.new_sym(ticker + '#bs_indicator_acc', this.PineJS.Std.period(this._context));
                // Register uvdv2 (3)
                context.new_sym(ticker.replace('-1', '-2') + '#bs_indicator_acc', this.PineJS.Std.period(this._context));
            }
            else
                return null;
        },
        main(context, inputCallback) {
            const interval = context.symbol.period;
            if (interval === '1') {
                const w1 = 300;
                const w2 = 960;
                const time = this._context.new_unlimited_var(this._context.symbol.time);
                context.select_sym(1);
                const bvav1 = this._context
                    .new_unlimited_var(this.PineJS.Std.open(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                const tbta1 = this._context
                    .new_unlimited_var(this.PineJS.Std.high(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                const bvav2 = this._context
                    .new_unlimited_var(this.PineJS.Std.low(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                const tbta2 = this._context
                    .new_unlimited_var(this.PineJS.Std.close(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                context.select_sym(2);
                const uvdv1 = this._context
                    .new_unlimited_var(this.PineJS.Std.open(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                context.select_sym(3);
                const uvdv2 = this._context
                    .new_unlimited_var(this.PineJS.Std.open(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                context.select_sym(0);
                const bvav1Series = this._context.new_var(bvav1);
                const tbta1Series = this._context.new_var(tbta1);
                const bvav2Series = this._context.new_var(bvav2);
                const tbta2Series = this._context.new_var(tbta2);
                const uvdv1Series = this._context.new_var(uvdv1);
                const uvdv2Series = this._context.new_var(uvdv2);
                bvav1Series.get(1000);
                tbta1Series.get(1000);
                bvav2Series.get(1000);
                tbta2Series.get(1000);
                uvdv1Series.get(1000);
                uvdv2Series.get(1000);
                const jj = this._context.new_var(bvav1Series.get(0) - tbta1Series.get(0));
                const hh = this._context.new_var(bvav2Series.get(0) - tbta2Series.get(0));
                const ii = this._context.new_var(uvdv2Series.get(0) + uvdv1Series.get(0));
                const gg = this._context.new_var(jj.get(0) + hh.get(0));
                const ll = this._context.new_var(this.PineJS.Std.sum(ii, w1, this._context));
                const mm = this._context.new_var(this.PineJS.Std.sum(gg, w1, this._context));
                const kk = this._context.new_var(this.PineJS.Std.sum(gg, w2, this._context));
                // console.log(`
                // 2330
                // bvav1: ${bvav1Series.get(0)}
                // bvav2: ${bvav2Series.get(0)}
                // tbta1: ${tbta1Series.get(0)}
                // tbta2: ${tbta2Series.get(0)}
                // uvdv1: ${uvdv1Series.get(0)}
                // uvdv2: ${uvdv2Series.get(0)}
                // ---------------------------------------
                // jj = ${bvav1Series.get(0)} - ${tbta1Series.get(0)} = ${jj.get(0)}
                // hh = ${bvav2Series.get(0)} - ${tbta2Series.get(0)} = ${hh.get(0)}
                // ii = ${uvdv2Series.get(0)} - ${uvdv1Series.get(0)} = ${ii.get(0)}
                // --------------------------------------
                // gg = ${jj.get(0)} + ${hh.get(0)} = ${gg.get(0)}
                // --------------------------------------
                // ll = ${ll.get(0)}
                // mm = ${mm.get(0)}
                // kk = ${kk.get(0)}
                // `)
                return [ll.get(0), mm.get(0), ll.get(0), kk.get(0)];
            }
            else
                return [NaN, NaN, NaN, NaN];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'll', type: 'line' },
            { id: 'mm', type: 'line' },
            { id: 'll2', type: 'line' },
            { id: 'kk', type: 'line' },
        ],
        defaults: {
            styles: {
                ll: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ffffff',
                },
                mm: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ffff00',
                },
                ll2: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#33ffff',
                },
                kk: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            ll1: {
                title: 'll',
                histogramBase: 0,
                joinPoints: false,
            },
            mm: {
                title: 'mm',
                histogramBase: 0,
                joinPoints: false,
            },
            ll2: {
                title: 'll',
                histogramBase: 0,
                joinPoints: false,
            },
            kk: {
                title: 'kk',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
