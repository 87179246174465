import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { dayStore } from '~/pages/daddy960_stocknerve/daddy960_stocknerve_SidePane1';
export const stocknerve_ma960SF = createIndicator({
    id: 'stocknerve-ma960SF',
    displayName: 'stocknerve-ma960',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const resolution = this._context.symbol.period;
            if (!(resolution === '1' ||
                resolution === '2' ||
                resolution === '3' ||
                resolution === '5' ||
                resolution === '15' ||
                resolution === '30' ||
                resolution === '60'))
                return;
            const length = Math.ceil(960 / parseInt(resolution));
            const ma = this.PineJS.Std.sma(this._context.new_var(this.PineJS.Std.close(this._context)), length, this._context);
            dayStore.load = true;
            return [ma];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [{ id: 'movAvg', type: 'line' }],
        defaults: {
            styles: {
                movAvg: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#33ffff',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            movAvg: {
                title: '960',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
