import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const daddy960_stocknerve_ma = createIndicator({
    displayName: '移動平均',
    id: 'daddy960-stocknerve-ma',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = this._input(0);
            const in2 = this._input(1);
            const in3 = this._input(2);
            const in4 = this._input(3);
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const sma3 = this.PineJS.Std.sma(close_array, in3, this._context);
            const sma4 = this.PineJS.Std.sma(close_array, in4, this._context);
            return [sma1, sma2, sma3, sma4];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#f2ab47',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#36dad8',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#67ad5b',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#9031aa',
                },
            },
            inputs: {
                in_0: 5,
                in_1: 10,
                in_2: 20,
                in_3: 60,
            },
        },
        styles: {
            plot_0: {
                title: '5日',
                histogramBase: 0,
            },
            plot_1: {
                title: '10日',
                histogramBase: 0,
            },
            plot_2: {
                title: '20日',
                histogramBase: 0,
            },
            plot_3: {
                title: '60日',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: '長度',
                defval: 5,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_1',
                name: '長度',
                defval: 10,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_2',
                name: '長度',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_3',
                name: '長度',
                defval: 60,
                type: 'integer',
                min: 1,
                max: 360,
            },
        ],
        scriptIdPart: '',
    },
});
