import { store } from '../heineken_template/_private/store';
import { stocknerve_1222 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_1222';
import { stocknerve_ma960SF } from '~/trades/indicators/daddy960_stocknerve/stocknerve_ma960';
import { stocknerve_2330 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_2330';
import { stocknerve_as1 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_as1';
import { VPVR } from '~/trades/indicators/defaults/VPVR';
import { daddy960_stocknerve_big_ratio } from '~/trades/indicators/daddy960_stocknerve/stocknerve_big_ration';
import { daddy960_stocknerve_total_people } from '~/trades/indicators/daddy960_stocknerve/stocknerve_total_people';
import { daddy960_stocknerve_small_ratio } from '~/trades/indicators/daddy960_stocknerve/stocknerve_small_ratio';
import { daddy960_stocknerve_ma } from '~/trades/indicators/daddy960_stocknerve/stocknerve_ma';
export const stockFutureIndicatorGroup = [
    VPVR,
    stocknerve_ma960SF,
    stocknerve_1222,
    stocknerve_as1,
    stocknerve_2330,
];
export const stockTdccIndicatorGroup = [
    VPVR,
    daddy960_stocknerve_big_ratio,
    daddy960_stocknerve_small_ratio,
    daddy960_stocknerve_total_people,
    daddy960_stocknerve_ma,
];
export const daddy960_stocknerve_strategyGroup = {
    main: [
        {
            displayName: 'Main strategy group',
            indicators: stockFutureIndicatorGroup,
            interval: '1',
            panesRatio: 66,
        },
        {
            displayName: 'TDCC',
            indicators: stockTdccIndicatorGroup,
            interval: '1D',
            panesRatio: 66,
        },
    ],
};
export const daddy960_stocknerve_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...stockFutureIndicatorGroup,
        ...stockTdccIndicatorGroup,
    ];
    store.charting.initStrategy({
        configs: [...daddy960_stocknerve_strategyGroup.main],
    });
};
